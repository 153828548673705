.projects {
  background-color: var(--white);
  padding: 3rem 1rem;
  margin-left: auto; 
  margin-right: auto; 
  max-width: 1200px; 
}

.projects__h2 {
  font-family: "montserrat";
}

.project__title {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

.projects__p {
  margin-bottom: 2rem;
  font-size: 1rem;
}

.projects-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.project-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 2rem;
}

.card-image {
  width: 100%;
  max-width: 300px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
}

.card-content {
  background-color: rgba(228, 167, 75, 0.9);
  padding: 1rem;
  width: 100%;
  max-width: 300px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
}

.project__description {
  font-size: 1rem;
  margin-top: 1rem; /* Add margin between title and description */
}

.tech-stack-list {
  list-style: none;
  padding: 0;
  font-size: small;
}

.tech-stack-label {
  font-weight: bold;
  color: #333;
}

.tech-stack-item {
  display: inline-block;
  margin-right: 0.5rem;
  padding: 0.2rem 0.3rem;
  background-color: #eaeaeaa5;
  border-radius: 3px;
  margin-bottom: 0.8rem;
  margin-top: 0.6rem;
}

.read-more-button {
  color: var(--red);
  font-weight: 800;
}

@media only screen and (min-width: 768px) {
  .projects {
    background-color: var(--white);
    padding: 4rem 2rem;
    max-width: 1200px; /* Ensure max-width on medium to large screens */
  }

  .projects__h2 {
    font-family: "montserrat";
  }

  .project__title {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    font-weight: 600;
    font-family: "montserrat";
  }

  .projects__p {
    margin-bottom: 3rem;
    font-size: 1.1rem;
  }

  .projects-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .project-card {
    display: flex;
    width: 100%;
    margin-bottom: 4rem;
  }

  .project-card:nth-child(even) {
    flex-direction: row-reverse;
  }

  .project-card:nth-child(odd) {
    flex-direction: row;
  }

  .project__description {
    margin-top: 0.5rem;
    margin-bottom: 1rem;
  }

  .card-image {
    width: 100%;
    height: auto;
    max-width: 45rem;
    box-shadow: 0 0.9rem 10px rgb(0 0 0 / 0.2);
  }

  .card-content {
    bottom: 0;
    left: 0;
    right: 5rem;
    min-width: 35rem;
    background-color: rgba(228, 167, 75, 0.9);
    padding: 1rem;
    box-shadow: 0 0.9rem 10px rgb(0 0 0 / 0.2);
  }

  .tech-stack-label {
    font-weight: bold;
    color: #333;
  }

  .tech-stack-list {
    list-style: none;
    padding: 0;
    margin-top: 0.5rem;
    font-size: small;
  }

  .tech-stack-item {
    display: inline-block;
    margin-right: 0.5rem;
    padding: 0.3rem 0.5rem;
    background-color: #eaeaeaa5;
    border-radius: 3px;
    margin-bottom: 1.3rem;
  }
}
