.project__buttons {
  display: flex;
  justify-content: flex-start;
}

.button {
  display: inline-block;
  padding: 0.5rem 1rem;
  font-size: 0.6rem;
  font-weight: bold;
  text-transform: uppercase;
  color: #fff;
  background-color: var(--red);
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.button:hover {
  background-color: #0056b3;
}

.button + .button {
  margin-left: 1rem;
}
