.skills__container {
  padding: 2rem; 
}

.landingpage__h3 {
  margin-top: 1rem;
  font-weight: 600;
  font-size: large;
}

.icon {
  width: 100%;
  height: auto;
}

ul.skill-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: grid; 
  grid-template-columns: repeat(3, 1fr); 
  gap: 0,5rem; 
}

@media (min-width: 640px) {
  ul.skill-list {
    grid-template-columns: repeat(3, 1fr); 
  }
}

@media (min-width: 768px) {
  ul.skill-list {
    grid-template-columns: repeat(4, 1fr); /* Four columns on larger screens */
  }
}

@media (min-width: 1024px) {
  ul.skill-list {
    grid-template-columns: repeat(10, 1fr); /* Five columns on wide screens */
  }
}

li.skill-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.skill-name {
  margin-top: 8px;
  font-size: 14px;
  font-weight: 500;
  color: #333;
}
