* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-color: rgb(255, 255, 255);
  font-family: "Montserrat", sans-serif;
}

@font-face {
  font-family: "alta_regular";
  src: url("/public/font/Alta_regular.otf") format("opentype");
}

@font-face {
  font-family: "montserrat";
  src: url("/public/font/Montserrat-VariableFont_wght.ttf");
}

:root {
  --gray: #d9d9d9;
  --white: #edebe7;
  --yellow: #d19843;
  --red: #8e1010;
  --blue: #004d9e;
}

.h2 {
  font-family: "alta_regular";
}
