.footer {
  padding: 2rem;
  background-color: var(--yellow);
  text-align: center;
  position: relative;
}

.footer__credits {
  font-family: "alta_regular";
  font-size: 1.5rem;
  font-weight: 500;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 1rem;
  margin: auto;
  bottom: 3rem;
}

.footer__title {
  font-size: larger;
  font-weight: 600;
}

.footer__content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.footer__contact-list {
  display: flex;
  list-style: none;
  gap: 1rem;
  padding: 0;
  margin: 0;
  margin-bottom: 5rem;
}

.footer__contact-item {
  text-align: center;
}

.footer__contact-link {
  display: inline-block;
  margin-top: 1.5rem;
}

.footer__contact-icon {
  width: 2rem;
  height: 2rem;
}

.footer__contact-icon:hover {
  filter: invert(20%) sepia(86%) saturate(590%) hue-rotate(352deg)
    brightness(97%) contrast(93%);
}

.footer_creditsp {
  color: var(--red);
  font-weight: 800;
}

.credit_small {
  font-size: small;
}

@media only screen and (min-width: 768px) {
  .footer {
    padding: 2rem;
    background-color: var(--yellow);
    text-align: center;
    position: relative;
  }

  .footer__title {
    font-size: larger;
    font-weight: 600;
  }

  .footer__content {
    display: flex;
    justify-content: space-between; /* Distribute items evenly */
    align-items: center;
  }

  .footer__contact-list {
    display: flex;
    list-style: none;
    gap: 2rem;
    padding: 0;
    margin: 0;
    justify-content: center;
  }

  .footer__contact-item {
    text-align: center;
  }

  .footer__contact-link {
    display: inline-block;
    margin-top: 2.4rem;
  }

  .footer__contact-icon {
    width: 2.5rem;
    height: 2.5rem;
    margin-bottom: 0;
  }

  .footer__contact-item:hover .footer__contact-icon,
  .footer__contact-item:hover .footer__contact-text {
    filter: invert(20%) sepia(86%) saturate(700%) hue-rotate(352deg)
      brightness(97%) contrast(93%);
    transition: filter 0.3s; 
  }

  .footer__credits {
    font-family: "alta_regular";
    font-size: 2rem;
    font-weight: 500;
    margin-left: 80rem;
    margin-bottom: -2.9rem;
  }

  .footer_creditsp {
    color: var(--red);
    font-weight: 600;
  }
}
