.header {
  position: fixed;
  top: 0;
  width: 100%;
  height: rem;
  z-index: 10;
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: var(--yellow);
}

.header__container {
  background-color: var(--yellow);
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.header__h1 {
  font-family: "alta_regular";
  color: black;
  font-size: 4rem;
  font-weight: 500;
  margin-left: 1.2rem;
}

.header__small {
  width: 25rem;
  background-color: white;
  text-align: center;
  position: relative;
  top: -1.2rem;
}

.header__p {
  font-size: small;
  font-family: "montserrat";
}
