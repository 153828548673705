@tailwind base;
@tailwind components;
@tailwind utilities;

@import url(../src/Blocks/Global.css);
@import url(../src/Blocks/Header.css);
@import url(../src/Blocks/LandingPage.css);
@import url(../src/Blocks/Skills.css);
@import url(../src/Blocks/Footer.css);
@import url(../src/Blocks/Project.css);
@import url(../src/Blocks/Button.css);
